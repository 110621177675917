<template>
  <div>
  <Top></Top>
    <HomeMain class="box99" :fatherList="fatherList" :sonList="sonList" />
  </div>
</template>

<script>
import HomeMain from "./HomeMain.vue";
import Top from "../../components/top/index.vue"
export default {
  name: "",
  components: {
    HomeMain,
    Top
  },
  data() {
    return {
      menuList:[],
      fatherList:[],
      sonList:[]
    };
  },
  created(){
    this.menuList = JSON.parse(decodeURIComponent(localStorage.getItem('menu')))
    this.handleMenuList()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleMenuList(){
      let lev1 = []
      let lev2 = []
      this.menuList.forEach(function (item, index) {
        if((item.superCode) === 0){
          lev1.push(item)
        }else{
          lev2.push(item)
        }
      });
      this.fatherList = lev1 
      this.sonList = lev2
    },
     //退出
    signout(){
      this.$http.post('/signout')
        .then(res=>{
        }).catch(res=>{
        }); 
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.box99 {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,#A2D0F2FF,#FBFDFFFF);
}
.login_top {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/头部底图.png);
  background-size: 100% 100%;
}
.logintop_min {
  width: 1200px;
  margin: auto;
}
.min_top {
  height: 160px;
  line-height: 160px;
  display: flex;
}
.top_img1 {
  display: flex;
  align-items: center;
}
.top_img2 {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
}
.gerenone {
  /* width: 222px; */
  height: 40px;
  display: flex;
  background-color: #fff;
  margin-top: 110px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.8;
  position: relative;
}
.gerenone img {
  width: 20px;
  height: 20px;
  padding: 0 10px;
}

.nav button {
  width: 185px !important;
  height: 56px !important;
  font-size: 16px !important;
  font-family: MicrosoftYaHei !important;
  color: #333333 !important;
  line-height: 16px !important;
  text-align: center;
  background-color: #ffffff !important;
  border: none !important;
}
.nav button:hover {
  width: 185px !important;
  height: 56px !important;
  background-color: rgba(50, 117, 214, 0.05) !important;
  font-size: 16px !important;
  font-family: MicrosoftYaHei !important;
  color: #3275d6 !important;
}

.nav {
  width: 190px;
  height: 168px;
  background: #ffffff;
  border-radius: 6px;

  position: absolute;
  top: 180px;
  left: 360px;
}

::v-deep .v-modal {
  z-index: 3000 !important;
  display: none !important;
  position: relative !important;
}
::v-deep .v-modal-enter {
  display: none !important;
  position: relative !important;
}
::v-deep .el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.7);
}
::v-deep .v-modal__wrapper {
  z-index: -1 !important;
}
::v-deep .el-dialog {
  width: 643px;
  height: 428px;
  background: #ffffff;
  border-radius: 10px;
}
.btn {
  width: 7px;
  height: 56px;
  background: #3275d6;
  // border-radius: 6px 0px 0px 0px;
  position: absolute;
  top: 0px;
  // display: none;
}
.btn4 {
  border-radius: 6px 0px 0px 0px;
}
.btn5 {
  top: 56px;
}
.btn6 {
  top: 112px;
  border-radius: 0px 0px 0px 6px;
}
</style>
