<template>
  <div class="box0">
      <!-- <div class="home_title">
        <div class="home_title_text">业务模块</div>
      </div> -->
    <div style="" class="boximg">

      <div class="card1">
        <div class="card11">
          <img src="../../assets/niu2.png" alt="" />
        </div>
        <div class="card12">
          <div
                  class="card1-1"
                  v-for="(itemson, indexson) in sonList"
                  :key="itemson.indexson"
                  v-if="itemson.superCode === 10"
                  @click="goUrl(itemson)"
          >
          <span class="bbb"  v-if="itemson.code === 11"><img src="../../assets/组 22 拷贝 3.png" alt=""/></span>
            <span class="bbb" v-if="itemson.code === 12"><img src="../../assets/组 22 拷贝.png" alt="" /></span>
            <span class="bbb" v-if="itemson.code === 13"><img src="../../assets/组 22 拷贝 2.png" alt="" /></span>
            <span class="bbb" v-if="itemson.code === 14"><img src="../../assets/组 22 拷贝 3.png" alt="" /></span>
            <main>{{ itemson.pname }}</main>
            
          </div>
        </div>
      </div>
      <div class="card1">
        <!-- <div class="card11">
          <img src="../../assets/zhu2.png" alt="" />
        </div> -->
        <div class="card13 cardone">
          <div class="card1-77" style="padding:0;width:155px;height:190px;margin-right:10px;">
                  <img src="../../assets/zhu.png" alt="" style="width:100%;height:100%;"/>
          </div>
          <div
                  class="card1-1 cardtwo"
                  v-for="(itemson, indexson) in sonList"
                  :key="itemson.indexson"
                  v-if="itemson.superCode === 20"
                  @click="goUrl(itemson)"
          >
           <!-- <img src="../../assets/组 20.png" alt="" /> -->
             <span class="bbb" v-if="itemson.code === 21"><img src="../../assets/组 22 拷贝 5.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 22"><img src="../../assets/组 22 拷贝 7.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 23"><img src="../../assets/组 22 拷贝 4.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 24"><img src="../../assets/组 22 拷贝 6.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 25"><img src="../../assets/组 22 拷贝 6.png" alt="" /></span>
            <main>{{ itemson.pname }}</main>
           
          </div>
        </div>
      </div>
      <div class="card1 card2">
        <div class="card11">
          <img src="../../assets/daozi2.png" alt="" />
        </div>
        <div class="card14">
          <div
                  class="card1-1"
                  v-for="(itemson, indexson) in sonList"
                  :key="itemson.indexson"
                  v-if="itemson.superCode === 30"
                  @click="goUrl(itemson)"
          >
            <span class="bbb" v-if="itemson.code === 31"><img src="../../assets/组 22 拷贝 10.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 32"><img src="../../assets/组 22 拷贝 9.png" alt="" /></span>
             <span class="bbb" v-if="itemson.code === 33"><img src="../../assets/组 22 拷贝 11.png" alt="" /></span>
             
            <main>{{ itemson.pname }}</main>
           
          </div>
        </div>
      </div>
      <div class="card1 card3">
        <div class="card11">
          <img src="../../assets/cao2.png" alt="" />
        </div>
        <div class="card15">
          <div
                  class="card1-1"
                  v-for="(itemson, indexson) in sonList"
                  :key="itemson.indexson"
                  v-if="itemson.superCode === 40"
                  @click="goUrl(itemson)"
          >
          <span class="bbb" v-if="itemson.code === 43"><img src="../../assets/组 22 拷贝 4.png" alt="" /></span>
            <span class="bbb" v-if="itemson.code === 41"><img src="../../assets/组 22 拷贝 12.png" alt="" /></span>
            <span class="bbb" v-if="itemson.code === 42"><img src="../../assets/组 22 拷贝 13.png" alt="" /></span>
            <main>{{ itemson.pname }}</main>
           
          </div>
        </div>
      </div>
        <div class="card1 card3">
        <div class="card11">
          <img src="../../assets/Data Query.png" alt="" />
        </div>
        <div class="card13">
          <div class="card1-1" v-for="(itemson, indexson) in sonList" :key="itemson.indexson" v-if="itemson.superCode === 60"
                  @click="goUrl(itemson)">
           <span class="bbb" v-if="itemson.code === 61"> <img src="../../assets/组 22 拷贝 17.png" alt=""/></span>
            <span class="bbb" v-if="itemson.code === 63"> <img src="../../assets/组 22 拷贝 16.png" alt=""/></span>
             <span class="bbb" v-if="itemson.code === 64"> <img src="../../assets/组 22 拷贝 14.png" alt=""/></span>
              <span class="bbb" v-if="itemson.code === 65"> <img src="../../assets/组 22 拷贝 15.png" alt=""/></span>
            <main>{{itemson.pname}}</main>
          </div>
        </div>
      </div>
        <div class="card1 card3">
        <div class="card11">
          <img src="../../assets/Data Visualization.png" alt="" />
        </div>
        <div class="card13">
          <div
                  class="card1-1"
                  v-for="(itemson, indexson) in sonList" :key="itemson.indexson" v-if="itemson.superCode === 90"
                  @click="goUrl(itemson)"
          >
         <span class="bbb"> <img src="../../assets/组 22 拷贝 18.png" alt=""/></span>
          <!-- <img src="../../assets/组 22 拷贝 18.png" alt="" /> -->
           
            <main>{{itemson.pname}}</main>
           
          </div>
        </div>
      </div>
    </div>



      
  </div>
</template>

<script>
import Feedcertificate from "../grasslanddata/components/feedcertificate.vue";
import Index from "../grasslanddata/content.vue";
    export default {
        name: "HomeMain",
        components: {
    Feedcertificate,
    Index,
  },
        props: {
            fatherList: {
                type: Array,
                default() {
                    return "";
                },
            },
            sonList: {
                type: Array,
                default() {
                    return "";
                },
            },
        },
        mounted() {
            //console.log(this.fatherList)
            //console.log(this.sonList)
            this.handleMenu();
        },
        data() {
            return {
                activeName: "second",
     ins:0,
      show:false,
      // input2:'',
      list: [
        { title: '行政区划编码' },
        { title: '数据共享接口' },
        { title: '畜牧兽医生产经营代码' },
        { title: '数据资源管理' },
      ],
                // show:1,
                radio1: "数据服务",
                menu1: [],
                menu2: [],
                menu3: [],
                menu4: [],
                menu5: [],
                menu6: [],
                menu7: [],
            };
        },
        methods: {
            // goUrl (num) {
            //   console.log(num)
            //   this.ins=num
            //   },
            dian(){
                this.show=1
            },
            dian1(){
                this.show=2
            },
            goUrl(item) {
              console.log(item)
              //  window.open(item.url)
              this.ins=item
              //获取sysuser
              this.$http({
                method: "post",
                url: "/home/getUser",
              })
              .then(res=>{
                if(res.data.AREA === null || res.data.PHONE === null || res.data.ORG_NAME === null || res.data.PERSON_TYPE ===null || res.data.FULLNAME === null){
                  this.$confirm('完善个人信息后才能访问该模块!', '提示', {
                    confirmButtonText: '去完善个人信息',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.$router.push('/UserCenter')
                  }).catch(() => {
                    this.$message({
                      showClose: true,
                      type: 'info',
                      message: '已取消'
                    });
                  });
                }else{
                  let params = new FormData();
                  params.append("menu", item);
                  this.$http.post("/home/recordOauthLog", params, {
                    headers: { "Content-Type": "application/json" },
                  }).then((res) => {});
                  window.open(item.url)
                }
              }).catch(res=>{
                console.log(res)
              });
            },
            handleMenu() {
                let lev1 = [];
                let lev2 = [];
                let lev3 = [];
                let lev4 = [];
                let lev5 = [];
                let lev6 = [];
                let lev7 = [];
                this.sonList.forEach(function (item, index) {
                    if (item.superCode === 10) {
                        lev1.push(item);
                    }
                    if (item.superCode === 20) {
                        lev2.push(item);
                    }
                    if (item.superCode === 30) {
                        lev3.push(item);
                    }
                    if (item.superCode === 40) {
                        lev4.push(item);
                    }
                    if (item.superCode === 50) {
                        lev5.push(item);
                    }
                    if (item.superCode === 60) {
                        lev6.push(item);
                    }
                    if (item.superCode === 70) {
                        lev7.push(item);
                    }
                });
                this.menu1 = lev1;
                this.menu2 = lev2;
                this.menu3 = lev3;
                this.menu4 = lev4;
                this.menu5 = lev5;
                this.menu6 = lev6;
                this.menu7 = lev7;
            },
        },
    };
</script>

<style lang="less" scoped>
.card12 .card1-1:nth-child(3){
  width: 100%;
  background-image: url('../../assets/组 23.png');
}
.card14 .card1-1:nth-child(3){
  width: 100%;
  background-image: url('../../assets/组 23.png');
}
.card15 .card1-1:nth-child(3){
  width: 100%;
  background-image: url('../../assets/组 23.png');
}
.bbb{
  width: 33px;
  height: 33px;
  background-color: #A7D3F6;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 50%;
}
.bbb img{
  // width: 20px !important;
  // height: 20px !important;
}
  .boximg{
   display: -webkit-flex;

            display:flex;

            -webkit-justify-content: space-between;

            justify-content: space-between;
    width: 1200px;margin:auto;
    flex-wrap: wrap;
  }
  .user-solid{
    width: 20px!important;
    height: 23px!important;
    background-image: url("../../assets/yonghu.png");
  }
  .card1 {
    background-color: #fff;
    height: 190px;
    width: 585px;
    display: flex;
    // position: relative;
    // display: inline-block;
    box-shadow: 0px 2px 10px 3px rgba(0, 47, 168, 0.23);
    border-radius: 8px;
    // margin-right: 10px;
    margin-bottom: 20px;
  }
  .card1 div {
    // display: inline-block;
  }
  .card11{
    // flex: 1;
// width: 190px;
  }
  .card11 img {
    margin-right: 10px;
    height: 100%;
    // width: 95%;
  }
  .card12,.card13,.card14,.card15{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
.cardone{
  // margin-left: 20px;
}
.cardtwo{
  // width: 135px !important;
}
  .card1-1 {
    height: 77px;
    width: 160px;
    padding: 0 20px;
    // margin-right: 10px;
    margin: 7px 10px 5px 0;
    // background-color: #eee;
    // position: relative;
    display: flex;
    align-items: center;
    //top: 10px;
    background: linear-gradient(180deg, #f1f4f7 0%, #feffff 99%);
    box-shadow: 0px 2px 4px 0px rgba(0, 47, 168, 0.2);
    border-radius: 6px;
    background: url("../../assets/back.png") no-repeat ;
    // background-size: 100% 100%;
    // background-position: -230px -193px;
    cursor:pointer;
    
  }
.card1-1:hover{
.bbb{
background-color: #3275D6;
    }
}
  .card1-1 img,
  .card1-2 img {
    width: 33px;
    height: 33px;
  }
  .card1-1 main,
  .card1-2 main {
    display: inline-block;
    // width: 162px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    padding-left: 10px;
    display: -webkit-box;
overflow: hidden;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
  }
  .card1-2 main {
    width: 252px !important;
  }
  .card1-2 {
    position: absolute;
    width: 370px;
    height: 144px;
    margin: 10px 0 0 0px;
    bottom: 10px;
    background-image: url("../../assets/组 19 拷贝.png");
    box-shadow: 0px 2px 4px 0px rgba(0, 47, 168, 0.2);
    border-radius: 6px;
    background-position: -2px -1px;
  }
  .card1-1-bottom {
    bottom: 10px !important;
    top: 164px;
  }
 
  ::v-deep .el-radio-button__orig-radio {
    width: 450px;
    height: 72px;
    background: #f5f5f5;
    border: 1px solid #e8e8e8;
  }
  ::v-deep .el-radio-button,
  ::v-deep .el-radio-button__inner {
    width: 450px !important;
    height: 72px !important;
    text-align: center;
    line-height: 48px;
    border-radius: 0px !important;
  }
  ::v-deep .el-radio-button__inner{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    line-height: 24px;
  }
  ::v-deep .is-active .el-radio-button__inner {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff !important;
  }
  .table-li {
    display: inline-block;
    height: 97px;
    position: relative;
  }
  .table-li div {
    width: 450px;
    height: 96px;
    display: inline-block;
    border: 1px solid #e8e8e8;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
  }
  .table-li div ul li {
    width: 200px;
    height: 36px;
    display: inline-block;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    list-style: disc;
  }
  .table-li div ul li:hover {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3275d6;
  }
  .table-text {
    display: inline-block;
    position: relative;
    top: -169px;
    right: -899px;
  }
  .table-text1 {
    width: 310px;
    height: 82px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 0px 8px 0px 0px;
    text-align: center;
    line-height: 85px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
  }
  .table-text2 {
    border-radius: 0px 0px 8px 0px;
  }
  .box0 {
    // margin-left: 358px;
    // margin-top: 35px;
    padding-top: 20px;
  }

  .box_footer{
    width: 1200px;
    margin: auto;
    display: flex;
    box-shadow: 0px 2px 10px 3px rgba(0, 47, 168, 0.23);
    border-radius: 6px;
  }
  .one{
    flex: 2;
    /* border: 1px solid #3275D6; */
    /* box-shadow: 0px 1px 5px 1px rgba(0, 72, 177, 0.78); */
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid #E8E8E8;
  }

  .one:last-child{
    flex: 1;
  }
  .one_title{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    text-align: center;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8E8E8;
    margin-bottom: 22px;
  }
  .one_title img{
    width: 28px;
    height: 26px;
    margin-right: 16px;
  }
  .one_title1 img{
    margin-right: 16px;
  }
  .one_title1{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    text-align: center;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }
  .one_title1:first-child{
    border-bottom: 1px solid #E8E8E8;
  }
  .one_bot{
    display: flex;
    justify-content: center;
    padding: 0 24px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .one_bot li{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171819;
    // flex: 1;
    width: 50%;
    cursor:pointer;
  }
  .one_bot li:hover{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3275D6;
  }
  // .active{
  //   /* background: #3275D6; */
  //   border: 1px solid #3275D6;
  //   border-radius: 8px 0px 0px 8px;
  // }
  .active {
  background: #e9f3ff;
  border: 1px solid #3275d6 !important;
  color: #3275d6 !important;
}
  .active1{
    background: #3275D6;
  }

  .card1-1:hover {
    //  overflow:hidden;
    animation-name: moveit;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    transition:0.3s;
    margin-top: 1px;
    // margin-left: 0px;
  }
.home_title {
  height: 33px;
  background-image: url(../../assets/ooo.png);
  background-size: 100% 100%;
  width: 1200px;
  margin: auto;
  margin-bottom: 14px;
}
.home_title_text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  padding-left: 23px;
  line-height: 33px;
  /* margin-top: 23px; */
}
.home_title1 {
  height: 33px;
  background-image: url(../../assets/lll.png);
  background-size: 100% 100%;
  margin-top: 22px;
  width: 1200px;
  margin: auto;
}
::v-deep .el-tabs__header{
  width: 1200px;
  margin: auto;
  padding-bottom: 15px;
}
.home_footer {
  display: flex;
  width: 1200px;
  margin: auto;
}
.home_footer_left {
  flex: 1;
}
.home_footer_right {
  flex: 1;
}
.home_footer_left_title {
  height: 33px;
  background-image: url(../../assets/left.png);
  background-size: 100% 100%;
  margin-top: 22px;
}
.home_footer_left_title_text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  padding-left: 23px;
  line-height: 33px;
}
.home_footer_left_title1 {
  height: 33px;
  background-image: url(../../assets/right.png);
  background-size: 100% 100%;
  margin-top: 22px;
}
.home_footer_left_con {
  display: flex;
  width: 585px;
  height: 155px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.home_footer_left_con_item {
  width: 264px;
  height: 50px;
  /* background: #E9F3FF; */
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-tabs__item{
  font-size: 16px;
}
::v-deep .el-tabs__content{
  overflow: initial;
}
</style>
